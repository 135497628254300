import React from "react"
import { Link, graphql } from "gatsby"
import { PageLayout, BlogLayout } from "../components/PageLayout"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

import {
  RowSection,
  TwoColumnSection,
  TwoColumnSectionLeft,
  TwoColumnSectionRight,
  OneColumnSection,
  OverlapRow,
  ThreeColumn,
  ThreeColumnOne,
  ThreeColumnTwo,
  ThreeColumnThree,
  ColumnInternal,
  ColumnInternalTop,
  ColumnInternalBottom,
} from "../components/SectionBlocks"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  return (
    <>
      <PageLayout>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          url={location.origin + location.pathname}
          ogImage={post.frontmatter.featuredImage.childImageSharp.fluid.src}
          pDate={post.frontmatter.date}
        />
        {/* <RowSection rowMargin="10%"> */}
        <RowSection rowMargin="1rem">
          <OneColumnSection sideMargin="10%" style={{ width: "100%" }}>
          {/* <OneColumnSection style={{ width: "100%" }}> */}
            {/* <Layout location={location} title={siteTitle}> */}
            <div className="mx-auto">
              <article className="blog-post" itemScope itemType="http://schema.org/Article">
                <header>
                  <h1 itemProp="headline">{post.frontmatter.title}</h1>
                  <p>{post.frontmatter.date}</p>
                </header>

                <section
                  className="prose md:prose-xl max-w-xs lg:max-w-7xl font-serif"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                  itemProp="articleBody"
                />

                <hr />
                <footer>
                  <Bio />
                </footer>
              </article>
              <nav className="blog-post-nav">
                <ul
                  style={{
                    display: `flex`,
                    flexWrap: `wrap`,
                    justifyContent: `space-between`,
                    listStyle: `none`,
                    padding: 0,
                  }}>
                  <li>
                    {previous && (
                      <Link to={previous.fields.slug} rel="prev">
                        ← {previous.frontmatter.title}
                      </Link>
                    )}
                  </li>
                  <li>
                    {next && (
                      <Link to={next.fields.slug} rel="next">
                        {next.frontmatter.title} →
                      </Link>
                    )}
                  </li>
                </ul>
              </nav>
              {/* </Layout> */}
            </div>
          </OneColumnSection>
        </RowSection>
      </PageLayout>
    </>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!, $previousPostId: String, $nextPostId: String) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
